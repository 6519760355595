.iframe-container {
  margin-top: 0.3rem;
  padding: 0 0.5rem;
  width: 100%;
  min-height: 35rem;
  text-align: left;
  margin-bottom: 10px;
  position: relative;
  .viewbutton-wrapper{
    position: absolute;
    right: 30px;
    top: 10px;
  }
  .down-btn{
    margin: 8px 0px 8px auto;
    display: flex;
      } 
 
    .not-supported{
      padding: 7px;
      box-shadow: #3c40434d 0px 1px 2px 0px, #3c404326 0px 1px 3px 1px;
      border-radius: 5px;
      min-height: 35rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .fileImage {
        position: relative;
        .fileMask {
            position: absolute;
            inset: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            background: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            opacity: 0;
            transition: opacity 0.3s;
            width: 131px;
            height: 131px;
            border-radius: 8px;

            span {
                padding-left: 3px;
            }
        }

        &:hover {
            .fileMask {
                opacity: 1;
            }
        }
    }
    .resourceName {
      p {
          color: #404040;
          width: 131px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
      }
  }
    }
    video{
      width: 100%;
      height: auto;
      border-radius: 5px;
      scale: unset;
      transform: unset;
    }
  }
  .iframe-image {
    width: 100%;
    min-height: 43rem;
    padding: 7px;
    box-shadow: #3c40434d 0px 1px 2px 0px, #3c404326 0px 1px 3px 1px;
    // border: 0.5px solid #00c6ec78;
    border-radius: 5px;
    max-width:710px;
    display:block;
    margin:0 auto;

  }