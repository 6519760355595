// @import "./assets/styles/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;800&display=swap");

.App {
  text-align: center;
}

.ant-input-group-addon {
  background-color: white !important;
}
// .ant-input {
//   background-color: #f9f9f9 !important;
// }

// .ant-select-selector {
//   background-color: #f9f9f9 !important;
// }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

iframe#webpack-dev-server-client-overlay{
  display:none!important
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
//new styles
.NewClassAnchor {
  align-items: flex-start;
  border-left: 2px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 50px;
  padding: 2px 2px 2px 10px;
  .scanName {
    margin-bottom: 8px;
    text-align: start;
  }
}

.scanName:hover {
  color: #27ddf5;
  cursor: pointer;
  transition: all 500ms ease;
}
.ant-upload-list.ant-upload-list-picture-card {
  text-align: left;
  padding: 0px 19px;
}
// add and edit patient
.inputTypeClass {
  width: 300px;
}
.dob-input {
  height: 39.5px;
}
.sectionInputType {
  display: flex;
  justify-content: space-between;
}

.ant-spin-nested-loading {
  height: 100%;
  .ant-spin-container {
    height: 100%;
  }
}

// notification component

.notifications-modal-container {
  .header-row {
    p {
      color: #9ca5bc;
      font-family: "Mulish", sans-serif;
      font-size: 16px;
      font-weight: 600;
      margin: 0px;
    }
  }
  .ant-form-item {
    margin-bottom: 0px !important;
  }
  .form-label {
    color: #3c4643;
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    font-weight: 500;
  }
  .notification-columnTwo {
    // text-align: right;
    padding-right: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .notification-select {
    justify-content: space-between;
    .duration-span {
      position: absolute;
      top: -25px;
    }
  }
  .onLabel {
    color: #000;
    font-family: "Mulish", sans-serif;
    // font-size: 18px;
    font-weight: 600;
    margin-left: 15px;
  }
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 80%;
  margin-bottom: 15px;

  .notification-wrapper {
    display: flex;
    align-items: flex-start;
    grid-gap: 3px;
    gap: 0px;
    align-items: center;
  }
}
.notification-counter {
  background: #ddfaff;
  padding: 3px;
  border-radius: 15px;
  color: #05a2bd;
  margin-bottom: 0;
}
.headerListPatient {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 10px;
}
.patientListLength {
  padding: 3px;
  background: #ddfaff;
  border-radius: 15px;
  color: #05a2bd;
  margin-top: 10px;
}
.notificationListLength {
  padding: 3px;
  background: #ddfaff;
  border-radius: 15px;
  color: #05a2bd;
  margin-top: 5px;
}

.ant-anchor::before {
  display: none !important;
}
.ant-tag {
  border: none;
}
.ant-btn-primary {
  box-shadow: none;
}

.ant-modal {
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-header {
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-modal-body {
    padding: 24px !important;
  }
  .ant-modal-footer {
    padding: 10px 16px;
    border-top: 1px solid #f0f0f0;
  }
}

.treatment-container {
  .treatment-colTwo{
    text-align: right;
  display: flex;
  justify-content: flex-end;

  }
  .treatment-header {
    color: #798c96;
    font-weight: 400;
    font-size: 14px;
    font-family: "Mulish", sans-serif;
    margin-bottom: 7px;
    margin-top: 0px;
  }
  .treatment-form{
    border: 1px dashed #d7d6d6;
    border-radius: 13px;
    padding: 1rem;
    margin-bottom: 15px;
    .ant-form-item{
      margin-bottom: 0px !important;
    }
  }
  .treatment-section {
    background: #fafafa;
    border: 1px dashed #d7d6d6;
    box-sizing: border-box;
    border-radius: 13px;
    padding: 1rem;
    // margin: 0 5px;
    margin-bottom: 10px;
    .treatment-label {
      color: #798c96;
      font-weight: 400;
      font-size: 14px;
      font-family: "Mulish", sans-serif;
      margin-bottom: 0px;
    }
    .treatment-info {
      color: rgba(34, 34, 34, 0.72);
      font-weight: 800;
      font-size: 14px;
      font-family: "Mulish", sans-serif;
    }
  }
}

//Remove space in required sign in ant forms
.ant-form {
  .ant-form-item-required {
    &::before {
      margin-inline-end: 0px !important;
    }
  }
}

.patient-created {
  cursor: pointer;
}

.sent-patient-tag {
  background: #e4f5eb;
  border-radius: 34px;
  color: green;
  padding: 0.4rem;
  width: 7rem;
  text-align: center;
  margin: 0px 0px 0px 10px;
  line-height: 24px;
}

.journey-status-value {
  text-wrap: pretty;
  text-transform: capitalize;
}
.treatment-origin-value {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  color: #222222;
  text-align: left;

  padding-right: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-tooltip-inner {
  padding: 5px 20px !important;
}

.ant-form-item-explain-error {
  text-align: left !important;
}

.tracking-container {
  display: flex;
  align-items: center;
  .tracking-url {
    width: 178px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .arrival-date {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3px;
    span{
     font-size: 12px;
      color: #9ca5bc;
    }
    b{
      font-size: 13px;
    }
  }
}

.smileAlign-app {
  font-family: "Roboto", sans-serif !important;
  //div
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span,
  .subheading,
  .caption,
  .ant-card-head-title,
  .content,
  span,
  a,
  td,
  th,
  .ant-typography,
  .ant-steps-item-title,
  .treatment-label,
  .treatment-info,
  .patientListLength,
  div,
  .patient-response {
    font-family: "Roboto", sans-serif !important;
  }
}

.labCostHide {
  display: none;
}
.smilelign-calendar {
  .rbc-header.rbc-now > a > span {
    color: #de8754 !important;
  }
  .rbc-date-cell.rbc-now > a,
  .rbc-date-cell.rbc-now > a:active,
  .rbc-date-cell.rbc-now > a:visited {
    background-color: #de8754 !important;
  }
}

//Notifications
.notificationShareModal {
  .users-shareList-container {
    .user-listItem {
      .del-btn {
        border: none !important;
        outline: none !important;
      }
      p {
        margin: 0px;
      }
    }
  }
}

.app-button-class {
  // min-width: 7rem;
  // height: 40px;
}

.errorContainer {
  max-width: 950px;
  margin: 0 auto;
  text-align: left;
  svg {
    width: 600px;
  }
  h1 {
    font-size: 35px;
    margin: 0px;
  }
  p {
    font-size: 25px;
    margin: 10px 0px 15px 0px;
  }
}

.refinement-detail-modal {
  .ant-divider{
    margin: 10px 0px !important;
  }
  .treatment-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  label {
    color: #9ca5bc;
    font-size: 18px;
    font-weight: 600;
  }
  .detail {
    color: #404040;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
  .images-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.resend-code-alert {
  .ant-alert-description {
    text-align: left;
  }
}


.whiteLabel-container {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  .whiteLabel-content {
    max-width: 70%;
    margin: 0 auto;
    text-align: left;
    h4 {
      font-family: "Poppins", sans-serif;
      text-align: left;
    }
    h5 {
      margin-top: 10px !important;
    }
    .ant-color-picker-trigger {
      width: 100%;
      text-align: left;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

@media print {
  @page {
    size: A4;
    margin: 10mm;
  }
}


.whitelabel-tooltip {
  &.anticon {
    margin-left: 5px;
    cursor: pointer;
  }
}


.new-alert-type1{
  background-color: #fafafa;
  border-color: #fafafa;
}

.emoji-font{
  font-family: "Noto Color Emoji", sans-serif !important;
}

.country-dropdown{
  width: 300px !important;
}

.country-select{
  .ant-select-selector{
  .country-name{
      display: none;
  }
  }
}

.order-invoice-modal{
  h1{
    text-align: center;
  }
  .download-allBtn{
    display: flex;
    align-items: center;
    gap:10px;
    justify-content: flex-end;
    color: #000;
    font-weight: 600;
    cursor: pointer;
  }
  .carousel-container{
    .arrow-icon{
     cursor: pointer;
    }
    .resource-container{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .resource{
      position: relative;
      margin: 40px auto;
      display: flex;
      align-items: center;
      flex-direction: column;
  
      .resource-wrapper{
      cursor: pointer;
       visibility: hidden;
       position: absolute;
       width:131px;
       height: 131px;
       background-color: rgba(0, 0, 0, 0.35);
       display: flex;
       align-items: center;
       justify-content: center;
       border-radius: 8px;
       svg{
        color: #fff;
       }
      }

    }
    .resource:hover{
      .resource-wrapper{
        visibility: visible;
        }
    }
  }
  .resources-container{
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    a {
        max-width: 200px;
    }

  }

}

.invoice-viewBtn{
  margin-right:10px;
  border:none;
  background: transparent;
  box-shadow: none;
  padding: 0px;
  height: auto;

}

.new-onboarding-container{
  height: max-content;
}

// .clinic-step-form-container {
//   .ant-form-item-explain-error {
//     min-height: 44px;
//   }
// }

.mobile-view-container {
  text-align: center;
  padding: 0 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  p{
    font-weight: bold;
  }
}

.brushbar-phone {
  top: 33px;
  left: -37px;
  position: relative;
  width: 80%;
}

.stroke-teeth-others{
  svg{
       stroke: #FF7676 !important;
      // stroke: red;
      stroke-width: 3px !important;
  }
}

.stroke-teeth-smilelign{
  svg{
       stroke: #00C6E9 !important;
      // stroke: red;
      stroke-width: 3px !important;
  }
}