.login-btn-container {
  //   margin-top: 1rem;
}

.login-container {
  background-color: white;
  height: 100%;
}

.login-button {
  width: 100% !important;
  height: 2rem !important;
  border-radius: 5px !important;
  background: #464646 !important;
  border-style: none !important;
  height: 2.5rem !important;
  font-weight: 700 !important;
}

.login-phone {
  position: absolute;
  left: -35px;
  bottom: 0px;
  z-index: 2;
  width: 25%;

  img {
    width: 100%;
  }

  // svg {
  //   height: 40%;
  //   width: 80%;
  // }
}

.login-image-container {
  height: 100vh;
  object-fit: cover;
  position: relative;
  overflow: hidden;

  .login-image-footer {
    position: absolute;
    bottom: 0px;
    width: calc(100% - 70px);
    border-radius: 0px 53px 0px 0px;
    background: #FFFFFFCC;
    backdrop-filter: blur(47px);
    padding: 15px 10px 20px 10px;
    left: 30px;

    p {
      font-size: 14px;
      margin: 0px;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
    }

    b {
      font-size: 16px;
      font-weight: 600;
      margin: 15px 0px 0px 0px;
      font-family: "Poppins", sans-serif;
    }

    // .login-desc {
    //   font-size: 12px;
    //   margin: 0px;
    //   font-family: "Poppins", sans-serif;
    //   font-weight: 400;
    //   // margin-top: 10px;
    //   text-align: center;
    //   padding-left: 4px;
    //   text-align: center;
    //   margin-left: 35px;
    //   max-width: 200px;
    // }
    .login-desc {
      font-size: 12px;
      margin: 0px;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      margin-top: 5px;
      text-align: center;
      max-width: 200px;
    }

    .login-scans {
      display: flex;
      gap: 10px;
      align-items: center;
      margin: -70px 10px 10px 0px;

      .store-icon {
        svg {
          width: 50px;
        }
      }

      .login-scan {
        text-align: center;
        box-shadow: 0px 4px 4px 0px #00000040;
        width: 100px;
        height: 100px;
        border-radius: 24px;
        padding: 10px;
        background: #FFFFFFCC;

        img {
          width: 100%;
        }

      }

    }



    .login-qr-container {
      display: flex;
      align-items: center;
      margin-top: -50%;
      gap: 6px;

      .login-qr {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .store-icon {
          svg {
            width: 50px;
            height: 50px;
          }
        }

        .qr-image {
          text-align: center;
          box-shadow: 0px 4px 4px 0px #00000040;
          width: 80px;
          height: 80px;
          border-radius: 24px;
          padding: 10px;
          background: #FFFFFFCC;

          img {
            width: 100%;
          }
        }
      }
    }

  }


}

.login-form-forgot {
  float: right !important;
}

.ant-col-rtl .login-form-forgot {
  float: left !important;
}

.image {
  height: 100vh !important;
  max-height: 1300px;
  object-fit: cover;
  width: 100% !important;
  object-position: top;
}

.login-title-container {
  text-align: left;
  // margin-left: 16%;
  margin-bottom: '3rem';
  //   background-color: blue;

  margin-top: 2rem;
}

@media only screen and (max-width: 992px) {
  .login-phone {
    display: none;
  }

  .image {
    display: none;
  }

  .login-title-container {
    text-align: center;
    padding-left: 0;
  }
}

@media only screen and (max-width: 1450px) {
  .login-image-container {
    height: 100%;

    // height: 100vh;
    object-fit: cover;
  }
}

@media only screen and (max-width: 570px) {
  .login-phone {
    display: none;
  }

  .login-btn-container {
    padding-left: 1.4rem;
  }

  .login-form {
    padding-right: 0.8rem !important;
  }
}

@media only screen and (max-width: 480px) {
  .login-phone {
    display: none;
  }

  // .title-container {
  //   text-align: left;

  //   padding-left: 4%;
  // }
  .login-logo {
    margin-left: 0 !important;
  }

  .login-form {
    padding-right: 0.8rem !important;
  }

  .login-btn-container {
    width: 100%;
    // margin: 7% auto 0 0;
    padding-left: 0.9rem;
  }

  .logo-title-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}