.doctors-app-container{
    .ant-modal-header {
        padding: 0px !important;
        border-radius: 4px !important;
    }
    .doctor-modal-header {
        // background: #000;
        p {
            // color: #FFF;
            font-size: 24px;
            font-weight: 400;
            padding: 20px 0px 20px 25px;
            margin: 0px;
            font-family: "Poppins", sans-serif;
            span {
                font-weight: 600;
                font-family: "Poppins", sans-serif;
            }
        }
    }
    .features-list{
        .title{
            font-size: 16px;
            font-weight: 600;
            margin: 15px 0px 0px 0px;
            font-family: "Poppins", sans-serif;
        }
    
    }
    .description {
        font-size: 14px;
        margin: 0px;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
    }
    .qr-scans{
        display: flex;
        gap: 14px;
        margin: 30px 0px 30px 40px;
        align-items: center;
        .qr-code-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .store-icon{
            svg{
                width: 50px;
                height: auto;
            }
        }
    }
    .qr-scan {
        text-align: center;
        box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.2509803922);
        width: 161px;
        height: 161px;
        gap: 0px;
        border-radius: 24px;
        padding: 10px;
    
        img{
            width: 100%;
        }
    }
    // .qr-row{
    //     margin-top: 30px;
    //     padding-left: 20px;
    //     // .scan-arrow{
    //     //     margin-bottom: 15px;
    //     // }
    //     p{
    //         font-size: 16px;
    //         margin: 0px;
    //     }
    // }
    .skip-footer{
        padding-bottom: 30px;
        .skip-footerBtn{
            float: right;
           width: 120px;
        }
    }


}



