.prescriptionPDF-container {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;

  .prescriptionPDF-SmileTreat {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .prescriptionPDF-peri {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }

  .prescriptionPDF-ins {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 30px;
  }

  .prescriptionPDF-occ {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    margin-top: 10px;
  }

  .prescriptionPDF-treat {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    margin-top: 10px;
  }

  .prescriptionPDF-duration {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 30px;
  }

  .prescriptionPDF-align {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
  }

  .prescriptionPDF-header {
    padding: 0px 10px;

    h1 {
      color: #00c6e9;
      text-align: left;
      font-size: 17px;
      font-family: "Mulish", sans-serif;
      font-weight: 700;
    }

    h2 {
      text-align: left;
      font-size: 15px;
      font-family: "Mulish", sans-serif;
      font-weight: 700;
    }
  }

  .smileAlign-header {
    h1 {
      color: #DE8754;
    }
  }
  .ias-header {
    h1 {
      color: #539ad1;
    }
  }
  .aqua-header {
    h1 {
      color: #00bff2;
    }
  }
  .brushbar-header {
    h1 {
      color: #0c77fa;
    }
  }

  .prescriptionPDF-patientRow {
    text-align: left;
    display: flex;
    align-items: flex-start;
    margin-top: 7px;

    label {
      font-weight: 400;
      color: #798c96;
      font-size: 13px;
      text-align: left;
      margin-bottom: 0px;
      margin-right: 15px;
      font-family: "Mulish", sans-serif;
      margin-right: 15px;
      min-width: 120px;
    }

    b {
      font-weight: 500;
      color: #222222;
      text-align: left;
      word-break: break-all;
      text-overflow: ellipsis;
      margin-bottom: 0px;
    }

    span {
      font-weight: 400;
      font-size: 13px;
      text-align: left;
      font-family: "Mulish", sans-serif;
      line-height: 20px;
    }
  }

  .prescriptionPDF-card {
    border-radius: 13px;
    border: 1px dashed #D7D6D6;
    background: #FAFAFA;
    padding: 10px;
    margin-bottom: 17px;
    text-align: left;


    h2 {
      text-align: left;
      font-size: 15px;
      font-family: "Mulish", sans-serif;
      font-weight: 700;
    }

    .border-right {
      border-right: 2px solid #e9e9e9;
    }

    .treatment-label {
      font-family: "Mulish", sans-serif;
      font-weight: 400;
      color: #798c96;
      font-size: 13px;
      text-align: left;
      margin-bottom: 0px;
    }

    p {
      font-weight: 500;
      color: #222222;
      margin-top: 0px;
      text-transform: capitalize;
    }

    .num-btn {
      border-radius: 50%;
      text-align: center;
      position: absolute;
      left: -7px;
      top: 15px;
      color: #fff;
      padding: 0px 7px;
    }

    .paddingLeft {
      padding-left: 10px;
    }

    .toothName {
      border-radius: 6px;
      color: #FFF;
      font-size: 14px;
      font-weight: 700;
      width: 100%;
      text-align: center;
      padding: 0px 10px;
      margin: 0;
    }

    .patient-image {
      width: 100%;
      height: auto;
    }
  }

  .no-photos-container {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .patient-images-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;

    .image-col {
      max-width: 200px;

      .patient-image {
        width: 100%;
        height: auto;
      }
    }

  }
}

@media print {
  .page-break {
    page-break-after: always;
    margin-top: 10px;
  }
}

@media print {
  @page {
    size: A4;
    /* DIN A4 standard, Europe */
    margin: 0;
    size: auto;
  }

  body {
    margin: 5mm 7mm 5mm 7mm;
  }

  div {
    page-break-inside: auto
  }

  div {
    page-break-inside: avoid;
    page-break-after: auto;
  }
}