.chat-window-container {
  position: fixed;
  bottom: 0px;
  right: 48px;
  z-index: 100;
  .chat-window-btn {
      width: 150px !important;
      height: 45px;
      border-radius: 8px  !important;
      min-width: 30px !important;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-badge-count{
      right: 0px !important;
      top: 4px !important;
      }
  }

    .chat-icon {
      font-size: 25px !important;
    }
  
    .chat-icon-unread {
      font-size: 25px !important;
    }

}

.chat-window-content{
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    .close{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: -10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .ant-radio-button-wrapper{
      width: 120px !important;
      text-align: center;
    }
  .header {
    padding: 10px;
    height: auto;
  }
  .chat-radio-group{
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .group-chat-radio {
    .ant-radio-button-wrapper:last-child {
      border-radius: 40px 40px 40px 40px !important;
    }
  }

  .normal-chat-radio {
    .ant-radio-button-wrapper:last-child {
      border-radius: 0 40px 40px 0 !important;
    }
  }
  
  .content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    background: #FAFAFA;
    border-radius: 10px;

  }
  
  .footer {
    background: #FAFAFA;
    padding: 10px;
    height: auto;
    .comment-editor{
    background: #fff;
    border-radius: 5px;
    padding: 0px 10px;
    display: flex;
    position: relative;
    align-items: flex-end;
    word-break: break-all;
    .upload-icon{
      margin-bottom: 13px;
    }
    .ql-toolbar{
      border: 1px solid #fff;
    }
    .ql-container.ql-snow {
      border: 1px solid #fff;
  }
  .ql-editor {
     min-height: 50px;
     max-height: 50px !important;
     word-break: break-word;
}
.send-icons{
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}
    }
  }
  .empty-msgs-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .empty{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p{
      color: #ACACACB8;

    }
  }
  }
}

.chat-window-drawer{
  .ant-drawer-header {
     display: none !important;
  }
}

